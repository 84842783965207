// #region DateTime

export class DateTime extends Date {
  /**
   * Creates a DateTime object that behaves like a regular Date but with
   * the ability to use fake timers for testing purposes.
   *
   * This constructor ensures that the DateTime class is compatible with
   * fake timers used in testing environments (e.g., Vitest), where
   * Date.now() is manipulated to simulate different times. When fake timers
   * are used, Date.now() returns the simulated time instead of the real current time.
   * This ensures that the DateTime instance behaves as expected when fake timers are active.
   *
   * @param {DateInput} date - The initial date value. If not provided,
   * the current system time (or the simulated time if fake timers are active) is used.
   *
   * @example
   * const dt = new DateTime();
   * // if fake timers are active, this will be the simulated time
   * const dt2 = new DateTime("2023-01-01T00:00:00Z");
   * // This will create a DateTime instance for the given date
   */
  constructor(date?: DateInput) {
    const systemTime = Date.now() // Fetches the current system time or simulated time
    super(date || systemTime) // Passes the simulated or real time to the Date constructor
  }

  /**
   * Returns whether the given time comes before the current time.
   *
   * @example
   * DateTime.from("1982-09-20").isBefore("1982-09-19") // false
   * DateTime.from("1982-09-20").isBefore("1982-09-20") // false
   * DateTime.from("1982-09-20").isBefore("1982-09-21") // true
   */
  isBefore(date: DateInput): boolean {
    return this < new Date(date)
  }

  /**
   * Returns whether the given time comes after the current time.
   *
   * @example
   * DateTime.from("1982-09-20").isAfter("1982-09-19") // true
   * DateTime.from("1982-09-20").isAfter("1982-09-20") // false
   * DateTime.from("1982-09-20").isAfter("1982-09-21") // false
   */
  isAfter(date: DateInput): boolean {
    return this > new Date(date)
  }

  /**
   * Returns whether the given time matches the current time, optionally for the given unit (e.g., 'year', 'month', 'day', 'week').
   *
   * @example
   * DateTime.from("1982-09-20").is("1982-09-19")     // false
   * DateTime.from("1982-09-20").is("1982-09-20")     // true
   * DateTime.from("1982-09-20").is("1982-09-21")     // false
   * DateTime.from("1982-09-20").is("1982-09-21", "month") // true
   * DateTime.from("1982-09-20").is("1982-10-22", "year")  // true
   */
  is(date: DateInput, unit?: DateUnit): boolean {
    const thisDate = new Date(this)
    const nextDate = new Date(date)

    switch (unit) {
      case "year":
        thisDate.setMonth(0)
        nextDate.setMonth(0)
      case "month":
        thisDate.setDate(1)
        nextDate.setDate(1)
      case "day":
        thisDate.setHours(0)
        nextDate.setHours(0)
      case "hour":
        thisDate.setMinutes(0)
        nextDate.setMinutes(0)
      case "minute":
        thisDate.setSeconds(0)
        nextDate.setSeconds(0)
      case "second":
        thisDate.setMilliseconds(0)
        nextDate.setMilliseconds(0)
    }

    return +thisDate === +nextDate
  }

  /**
   * Returns a new DateTime from the current time with the given time added.
   *
   * @example
   * DateTime.from("1982-09-20").with(1, "day")   // DateTime 1982-09-21
   * DateTime.from("1982-09-20").with(1, "month") // DateTime 1982-10-20
   * DateTime.from("1982-09-20").with(1, "year")  // DateTime 1983-09-20
   */
  with(number: number, unit?: DateUnit): DateTime {
    const datetime = new DateTime(this)

    if (unit === "week") {
      datetime.setDate(this.getDate() + number * 7)
    } else if (unit && unit in _mapOfDateFns) {
      datetime[("set" + _mapOfDateFns[unit]) as Setter<typeof unit>](
        datetime[("get" + _mapOfDateFns[unit]) as Getter<typeof unit>]() + number,
      )
    } else {
      datetime.setTime(this.getTime() + number)
    }

    return datetime
  }

  /**
   * Returns a relative time string describing the time passed since the given time until the current time.
   *
   * @example
   * DateTime.from("1982-09-20").since("1983-09-06") // "1 year ago"
   * DateTime.from("1982-09-20").since()             // "42 years ago"
   */
  since(time: DateInput = Date.now(), options?: RelativeTimeFormatOptions): string {
    const { locale, unit, ...format } = {
      locale: DateTime.locale,
      timeZone: DateTime.timeZone,
      ...Object(options),
    }

    const rtf = new Intl.RelativeTimeFormat(locale, { ...format })

    // @ts-ignore left-hand date coerces to number
    const delta = new Date(time) - new Date(this)

    if (_isInvalidDate(delta)) return "Invalid DateTime"

    for (const [_unit, _ms] of _formats) {
      const value = Math.round(delta / _ms)

      if (unit === _unit || (!unit && value !== 0)) {
        return rtf.format(-value, _unit)
      }
    }

    return rtf.format(-Math.floor(delta / 1e3), "second")
  }

  /**
   * Returns a relative time string describing the time left since the current time until the given time.
   *
   * @example
   * DateTime.from("1982-09-20").until("1983-09-06")
   * // "in 1 year"
   *
   * DateTime.from("1982-09-20").until()
   * // "in 42 years"
   */
  until(time: DateInput = Date.now(), options?: RelativeTimeFormatOptions): string {
    return new DateTime(time).since(this, options)
  }

  toParts(
    options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      timeZoneName: "short",
    } as DateTimeFormatOptions,
  ): Intl.DateTimeFormatPart[] {
    if (_isInvalidDate(this)) return []

    const { locale, timeStyle, ...format } = {
      locale: DateTime.locale,
      timeZone: DateTime.timeZone,
      ...Object(options),
    }

    if (Object.keys(format).length === 1) {
      // @ts-ignore deliberately re-introduce `timeStyle`
      format.timeStyle = timeStyle
    }

    const formatter = new Intl.DateTimeFormat(locale, format)

    return formatter.formatToParts(this).map(
      _formatTimePart({
        ...formatter.resolvedOptions(),
        timeStyle,
        ...format,
      }),
    )
  }

  /**
   * Returns a string representing the current time.
   *
   * @example
   * DateTime.from("1982-09-20").toString()
   * // "Sunday, September 19, 1982 at 8:00 PM EDT" // en-US & America/New_York
   * // "Monday 20 September 1982 at 1:00 BST"      // en-GB" & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ dateStyle: "full" })
   * // "Sunday, September 19, 1982" // en-US & America/New_York
   * // "Monday 20 September 1982"   // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ dateStyle: "medium" })
   * // "Sep 19, 1982" // en-US & America/New_York
   * // "20 Sept 1982" // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ dateStyle: "short" })
   * // "9/19/82"    // en-US & America/New_york
   * // "20/09/1982" // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ timeStyle: "full" })
   * // "8:00:00 PM Eastern Daylight Time" // en-US & America/New_York
   * // "01:00:00 British Summer Time"     // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ timeStyle: "long" })
   * // "8:00:00 PM EDT" // en-US & America/New_York
   * // "8:00:00 PM"     // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ timeStyle: "medium" })
   * // "2:40:50 PM" // en-US & America/New_York
   * // "01:00:00"   // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ timeStyle: "short" })
   * // "8:00 PM" // en-US & America/New_York
   * // "1:00"    // en-GB & Europe/London
   */
  toString(options?: DateTimeFormatOptions): string {
    if (_isInvalidDate(this)) return "Invalid DateTime"

    return this.toParts(options)
      .map((part) => part.value)
      .join("")
  }

  /**
   * Returns a string representing the current time in Local Time Zone (LTZ) in the date time string format.
   *
   * @example
   * date.toLTZString()       // "1982-09-20T00:00:00.000"
   * date.toLTZString("date") // "1982-09-20"
   * date.toLTZString("time") // "00:00"
   */
  toLTZString(unit?: "date" | "time") {
    const date =
      String(this.getFullYear()).padStart(4, "0") +
      "-" +
      String(this.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(this.getDate()).padStart(2, "0")

    const time =
      String(this.getHours()).padStart(2, "0") + ":" + String(this.getMinutes()).padStart(2, "0")

    const secs =
      String(this.getSeconds()).padStart(2, "0") +
      "." +
      String(this.getMilliseconds()).padStart(3, "0")

    return unit === "date" ? date : unit === "time" ? time : date + "T" + time + ":" + secs
  }

  /**
   * Returns a DateTime object from the given time or the present time.
   *
   * @example
   * DateTime.from("1982-09-20") // September 20, 1982 (UTC)
   *
   * DateTime.from("1982-09-20") // now
   */
  static from(): DateTime
  static from(value: DateInput): DateTime
  static from(
    year: number,
    month: number,
    date?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    ms?: number,
  ): DateTime
  static from(...args: unknown[]): DateTime {
    // @ts-ignore
    return new DateTime(...args)
  }

  /**
   * Returns a relative time string describing the time left since the given time until the present time.
   *
   * @example
   * DateTime.since("1982-09-20") // "in 42 years"
   * DateTime.since("2082-09-20") // "58 years ago"
   */
  static since(time: DateInput, options?: RelativeTimeFormatOptions): string {
    return DateTime.from().since(time, options)
  }

  /**
   * Returns a relative time string describing the time left since the present time until the given time.
   *
   * @example
   * DateTime.until("1982-09-20") // "42 years ago"
   * DateTime.until("2082-09-20") // "in 58 years"
   */
  static until(time: DateInput, options?: RelativeTimeFormatOptions): string {
    return new DateTime(time).since(Date.now(), options)
  }

  /**
   * Returns whether the given time comes before the present time.
   *
   * @example
   * DateTime.isBefore("1982-09-20") // false
   * DateTime.isBefore("9982-09-20") // true
   */
  static isBefore(date: DateInput): boolean {
    return DateTime.from().isBefore(date)
  }

  /**
   * Returns whether the given time comes after the present time.
   *
   * @example
   * DateTime.isAfter("1982-09-20") // true
   * DateTime.isAfter("9982-09-20") // false
   */
  static isAfter(date: DateInput): boolean {
    return DateTime.from().isAfter(date)
  }

  /**
   * Returns a new DateTime of the present time with the given time added.
   *
   * @example
   * // were the date 1982-09-20
   * DateTime.with(1, "day")   // DateTime 1982-09-21
   * DateTime.with(1, "month") // DateTime 1982-10-20
   * DateTime.with(1, "year")  // DateTime 1983-09-20
   */
  static with(number: number, unit?: DateUnit): DateTime {
    return DateTime.from().with(number, unit)
  }

  /**
   * Returns a string representing the present time.
   *
   * @example
   * DateTime.toString()
   * // "Sunday, September 19, 1982 at 8:00 PM EDT" // en-US & America/New_York
   * // "Monday 20 September 1982 at 1:00 BST"      // en-GB" & Europe/London
   *
   * DateTime.toString({ dateStyle: "full" })
   * // "Sunday, September 19, 1982" // en-US & America/New_York
   * // "Monday 20 September 1982"   // en-GB & Europe/London
   *
   * DateTime.toString({ dateStyle: "medium" })
   * // "Sep 19, 1982" // en-US & America/New_York
   * // "20 Sept 1982" // en-GB & Europe/London
   *
   * DateTime.toString({ dateStyle: "short" })
   * // "9/19/82"    // en-US & America/New_york
   * // "20/09/1982" // en-GB & Europe/London
   *
   * DateTime.toString({ timeStyle: "full" })
   * // "8:00:00 PM Eastern Daylight Time" // en-US & America/New_York
   * // "01:00:00 British Summer Time"     // en-GB & Europe/London
   *
   * DateTime.toString({ timeStyle: "long" })
   * // "8:00:00 PM EDT" // en-US & America/New_York
   * // "8:00:00 PM"     // en-GB & Europe/London
   *
   * DateTime.toString({ timeStyle: "medium" })
   * // "2:40:50 PM" // en-US & America/New_York
   * // "01:00:00"   // en-GB & Europe/London
   *
   * DateTime.toString({ timeStyle: "short" })
   * // "8:00 PM" // en-US & America/New_York
   * // "1:00"    // en-GB & Europe/London
   */
  static toString(options?: DateTimeFormatOptions): string {
    return DateTime.from().toString(options)
  }

  /** BCP 47 Language tag. */
  static locale = new Intl.DateTimeFormat().resolvedOptions().locale

  /** IANA time zone name. */
  static timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
}

// #endregion

// #region Internals

const _isInvalidDate = isNaN as (value: any) => boolean

const _isNumeric = RegExp.prototype.test.bind(/^\d+$/)

const _formatTimePart =
  (options: Intl.ResolvedDateTimeFormatOptions) =>
  <T extends Intl.DateTimeFormatPart>(part: T) =>
    ({
      type: part.type,
      value:
        // 2-digit hour
        part.type === "hour" && options.hour === "2-digit" && _isNumeric(part.value)
          ? String(Number(part.value)).padStart(2, "0")
          : // numeric hour
            part.type === "hour" &&
              (options.hour === "numeric" || options.timeStyle === "short") &&
              _isNumeric(part.value)
            ? String(Number(part.value))
            : // 2-digit minute
              part.type === "minute" && options.minute === "2-digit" && _isNumeric(part.value)
              ? String(Number(part.value)).padStart(2, "0")
              : // numeric minute
                part.type === "minute" && options.minute === "numeric" && _isNumeric(part.value)
                ? String(Number(part.value))
                : // 2-digit second
                  part.type === "second" && options.second === "2-digit" && _isNumeric(part.value)
                  ? String(Number(part.value)).padStart(2, "0")
                  : // numeric second
                    part.type === "second" && options.second === "numeric" && _isNumeric(part.value)
                    ? String(Number(part.value))
                    : // any other value
                      part.value,
    }) as T

const _mapOfDateFns = {
  year: "FullYear",
  month: "Month",
  day: "Date",
  hour: "Hours",
  minute: "Minutes",
  second: "Seconds",
} as {
  year: "FullYear"
  month: "Month"
  day: "Date"
  hour: "Hours"
  minute: "Minutes"
  second: "Seconds"
  millisecond: "Time"
}

type Getter<T extends keyof typeof _mapOfDateFns> = `get${(typeof _mapOfDateFns)[T]}`
type Setter<T extends keyof typeof _mapOfDateFns> = `set${(typeof _mapOfDateFns)[T]}`

const _formats = [
  ["year", 31536e6],
  ["month", 2592e6],
  ["week", 6048e5],
  ["day", 864e5],
  ["hour", 36e5],
  ["minute", 6e4],
] as const

// #endregion

// #region DateTime Types

export interface DateTimeFormatOptions extends Intl.DateTimeFormatOptions {
  locale?: Locale
  timeZone?: TimeZone
}

export interface RelativeTimeFormatOptions extends Intl.RelativeTimeFormatOptions {
  locale?: Locale
  unit?: DateUnit
}

export interface DateTimeOptions
  extends Required<DateTimeFormatOptions>,
    Required<RelativeTimeFormatOptions> {}

export type DateInput = number | string | Date

export type DateUnit =
  | "year"
  | "month"
  | "week"
  | "day"
  | "hour"
  | "minute"
  | "second"
  | "millisecond"

export type Locale =
  | "aa"
  | "ab"
  | "ae"
  | "af"
  | "ak"
  | "am"
  | "an"
  | "ar"
  | "as"
  | "av"
  | "ay"
  | "az"
  | "ba"
  | "be"
  | "bg"
  | "bh"
  | "bi"
  | "bm"
  | "bn"
  | "bo"
  | "br"
  | "bs"
  | "ca"
  | "ce"
  | "ch"
  | "co"
  | "cr"
  | "cs"
  | "cu"
  | "cv"
  | "cy"
  | "da"
  | "de"
  | "dv"
  | "dz"
  | "ee"
  | "el"
  | "en"
  | "eo"
  | "es"
  | "et"
  | "eu"
  | "fa"
  | "ff"
  | "fi"
  | "fj"
  | "fo"
  | "fr"
  | "fy"
  | "ga"
  | "gd"
  | "gl"
  | "gn"
  | "gu"
  | "gv"
  | "ha"
  | "he"
  | "hi"
  | "ho"
  | "hr"
  | "ht"
  | "hu"
  | "hy"
  | "hz"
  | "ia"
  | "id"
  | "ie"
  | "ig"
  | "ii"
  | "ik"
  | "io"
  | "is"
  | "it"
  | "iu"
  | "ja"
  | "jv"
  | "ka"
  | "kg"
  | "ki"
  | "kj"
  | "kk"
  | "kl"
  | "km"
  | "kn"
  | "ko"
  | "kr"
  | "ks"
  | "ku"
  | "kv"
  | "kw"
  | "ky"
  | "la"
  | "lb"
  | "lg"
  | "li"
  | "ln"
  | "lo"
  | "lt"
  | "lu"
  | "lv"
  | "mg"
  | "mh"
  | "mi"
  | "mk"
  | "ml"
  | "mn"
  | "mr"
  | "ms"
  | "mt"
  | "my"
  | "na"
  | "nb"
  | "nd"
  | "ne"
  | "ng"
  | "nl"
  | "nn"
  | "no"
  | "nr"
  | "nv"
  | "ny"
  | "oc"
  | "oj"
  | "om"
  | "or"
  | "os"
  | "pa"
  | "pi"
  | "pl"
  | "ps"
  | "pt"
  | "qu"
  | "rm"
  | "rn"
  | "ro"
  | "ru"
  | "rw"
  | "sa"
  | "sc"
  | "sd"
  | "se"
  | "sg"
  | "si"
  | "sk"
  | "sl"
  | "sm"
  | "sn"
  | "so"
  | "sq"
  | "sr"
  | "ss"
  | "st"
  | "su"
  | "sv"
  | "sw"
  | "ta"
  | "te"
  | "tg"
  | "th"
  | "ti"
  | "tk"
  | "tl"
  | "tn"
  | "to"
  | "tr"
  | "ts"
  | "tt"
  | "tw"
  | "ty"
  | "ug"
  | "uk"
  | "ur"
  | "uz"
  | "ve"
  | "vi"
  | "vo"
  | "wa"
  | "wo"
  | "xh"
  | "yi"
  | "yo"
  | "za"
  | "zh"
  | "zu"
  | (string & Record<never, never>) // any other locale

export type TimeZone =
  | "Africa/Abidjan"
  | "Africa/Accra"
  | "Africa/Addis_Ababa"
  | "Africa/Algiers"
  | "Africa/Asmara"
  | "Africa/Bamako"
  | "Africa/Bangui"
  | "Africa/Banjul"
  | "Africa/Bissau"
  | "Africa/Blantyre"
  | "Africa/Brazzaville"
  | "Africa/Bujumbura"
  | "Africa/Cairo"
  | "Africa/Casablanca"
  | "Africa/Ceuta"
  | "Africa/Conakry"
  | "Africa/Dakar"
  | "Africa/Dar_es_Salaam"
  | "Africa/Djibouti"
  | "Africa/Douala"
  | "Africa/El_Aaiun"
  | "Africa/Freetown"
  | "Africa/Gaborone"
  | "Africa/Harare"
  | "Africa/Johannesburg"
  | "Africa/Juba"
  | "Africa/Kampala"
  | "Africa/Khartoum"
  | "Africa/Kigali"
  | "Africa/Kinshasa"
  | "Africa/Lagos"
  | "Africa/Libreville"
  | "Africa/Lome"
  | "Africa/Luanda"
  | "Africa/Lubumbashi"
  | "Africa/Lusaka"
  | "Africa/Malabo"
  | "Africa/Maputo"
  | "Africa/Maseru"
  | "Africa/Mbabane"
  | "Africa/Mogadishu"
  | "Africa/Monrovia"
  | "Africa/Nairobi"
  | "Africa/Ndjamena"
  | "Africa/Niamey"
  | "Africa/Nouakchott"
  | "Africa/Ouagadougou"
  | "Africa/Porto-Novo"
  | "Africa/Sao_Tome"
  | "Africa/Tripoli"
  | "Africa/Tunis"
  | "Africa/Windhoek"
  | "America/Adak"
  | "America/Anchorage"
  | "America/Anguilla"
  | "America/Antigua"
  | "America/Araguaina"
  | "America/Argentina/Buenos_Aires"
  | "America/Argentina/Catamarca"
  | "America/Argentina/Cordoba"
  | "America/Argentina/Jujuy"
  | "America/Argentina/La_Rioja"
  | "America/Argentina/Mendoza"
  | "America/Argentina/Rio_Gallegos"
  | "America/Argentina/Salta"
  | "America/Argentina/San_Juan"
  | "America/Argentina/San_Luis"
  | "America/Argentina/Tucuman"
  | "America/Argentina/Ushuaia"
  | "America/Aruba"
  | "America/Asuncion"
  | "America/Atikokan"
  | "America/Bahia"
  | "America/Bahia_Banderas"
  | "America/Barbados"
  | "America/Belem"
  | "America/Belize"
  | "America/Blanc-Sablon"
  | "America/Boa_Vista"
  | "America/Bogota"
  | "America/Boise"
  | "America/Cambridge_Bay"
  | "America/Campo_Grande"
  | "America/Cancun"
  | "America/Caracas"
  | "America/Cayenne"
  | "America/Cayman"
  | "America/Chicago"
  | "America/Chihuahua"
  | "America/Costa_Rica"
  | "America/Creston"
  | "America/Cuiaba"
  | "America/Curacao"
  | "America/Danmarkshavn"
  | "America/Dawson"
  | "America/Dawson_Creek"
  | "America/Denver"
  | "America/Detroit"
  | "America/Dominica"
  | "America/Edmonton"
  | "America/Eirunepe"
  | "America/El_Salvador"
  | "America/Fortaleza"
  | "America/Glace_Bay"
  | "America/Godthab"
  | "America/Goose_Bay"
  | "America/Grand_Turk"
  | "America/Grenada"
  | "America/Guadeloupe"
  | "America/Guatemala"
  | "America/Guayaquil"
  | "America/Guyana"
  | "America/Halifax"
  | "America/Havana"
  | "America/Hermosillo"
  | "America/Indiana/Indianapolis"
  | "America/Indiana/Knox"
  | "America/Indiana/Marengo"
  | "America/Indiana/Petersburg"
  | "America/Indiana/Tell_City"
  | "America/Indiana/Vevay"
  | "America/Indiana/Vincennes"
  | "America/Indiana/Winamac"
  | "America/Inuvik"
  | "America/Iqaluit"
  | "America/Jamaica"
  | "America/Juneau"
  | "America/Kentucky/Louisville"
  | "America/Kentucky/Monticello"
  | "America/Kralendijk"
  | "America/La_Paz"
  | "America/Lima"
  | "America/Los_Angeles"
  | "America/Lower_Princes"
  | "America/Maceio"
  | "America/Managua"
  | "America/Manaus"
  | "America/Marigot"
  | "America/Martinique"
  | "America/Matamoros"
  | "America/Mazatlan"
  | "America/Menominee"
  | "America/Merida"
  | "America/Metlakatla"
  | "America/Mexico_City"
  | "America/Miquelon"
  | "America/Moncton"
  | "America/Monterrey"
  | "America/Montevideo"
  | "America/Montserrat"
  | "America/Nassau"
  | "America/New_York"
  | "America/Nipigon"
  | "America/Nome"
  | "America/Noronha"
  | "America/North_Dakota/Beulah"
  | "America/North_Dakota/Center"
  | "America/North_Dakota/New_Salem"
  | "America/Ojinaga"
  | "America/Panama"
  | "America/Pangnirtung"
  | "America/Paramaribo"
  | "America/Phoenix"
  | "America/Port-au-Prince"
  | "America/Port_of_Spain"
  | "America/Porto_Velho"
  | "America/Puerto_Rico"
  | "America/Punta_Arenas"
  | "America/Rainy_River"
  | "America/Rankin_Inlet"
  | "America/Recife"
  | "America/Regina"
  | "America/Resolute"
  | "America/Rio_Branco"
  | "America/Santarem"
  | "America/Santiago"
  | "America/Santo_Domingo"
  | "America/Sao_Paulo"
  | "America/Scoresbysund"
  | "America/Sitka"
  | "America/St_Barthelemy"
  | "America/St_Johns"
  | "America/St_Kitts"
  | "America/St_Lucia"
  | "America/St_Thomas"
  | "America/St_Vincent"
  | "America/Swift_Current"
  | "America/Tegucigalpa"
  | "America/Thule"
  | "America/Tijuana"
  | "America/Toronto"
  | "America/Tortola"
  | "America/Vancouver"
  | "America/Whitehorse"
  | "America/Winnipeg"
  | "America/Yakutat"
  | "America/Yellowknife"
  | "Antarctica/Casey"
  | "Antarctica/Davis"
  | "Antarctica/DumontDUrville"
  | "Antarctica/Macquarie"
  | "Antarctica/Mawson"
  | "Antarctica/Palmer"
  | "Antarctica/Rothera"
  | "Antarctica/Syowa"
  | "Antarctica/Troll"
  | "Antarctica/Vostok"
  | "Arctic/Longyearbyen"
  | "Asia/Aden"
  | "Asia/Almaty"
  | "Asia/Amman"
  | "Asia/Anadyr"
  | "Asia/Aqtau"
  | "Asia/Aqtobe"
  | "Asia/Ashgabat"
  | "Asia/Atyrau"
  | "Asia/Baghdad"
  | "Asia/Bahrain"
  | "Asia/Baku"
  | "Asia/Bangkok"
  | "Asia/Barnaul"
  | "Asia/Beirut"
  | "Asia/Bishkek"
  | "Asia/Brunei"
  | "Asia/Chita"
  | "Asia/Choibalsan"
  | "Asia/Colombo"
  | "Asia/Damascus"
  | "Asia/Dhaka"
  | "Asia/Dili"
  | "Asia/Dubai"
  | "Asia/Dushanbe"
  | "Asia/Famagusta"
  | "Asia/Gaza"
  | "Asia/Hebron"
  | "Asia/Ho_Chi_Minh"
  | "Asia/Hong_Kong"
  | "Asia/Hovd"
  | "Asia/Irkutsk"
  | "Asia/Jakarta"
  | "Asia/Jayapura"
  | "Asia/Jerusalem"
  | "Asia/Kabul"
  | "Asia/Kamchatka"
  | "Asia/Karachi"
  | "Asia/Kathmandu"
  | "Asia/Khandyga"
  | "Asia/Kolkata"
  | "Asia/Krasnoyarsk"
  | "Asia/Kuala_Lumpur"
  | "Asia/Kuching"
  | "Asia/Kuwait"
  | "Asia/Macau"
  | "Asia/Magadan"
  | "Asia/Makassar"
  | "Asia/Manila"
  | "Asia/Muscat"
  | "Asia/Nicosia"
  | "Asia/Novokuznetsk"
  | "Asia/Novosibirsk"
  | "Asia/Omsk"
  | "Asia/Oral"
  | "Asia/Phnom_Penh"
  | "Asia/Pontianak"
  | "Asia/Pyongyang"
  | "Asia/Qatar"
  | "Asia/Qostanay"
  | "Asia/Qyzylorda"
  | "Asia/Riyadh"
  | "Asia/Sakhalin"
  | "Asia/Samarkand"
  | "Asia/Seoul"
  | "Asia/Shanghai"
  | "Asia/Singapore"
  | "Asia/Srednekolymsk"
  | "Asia/Taipei"
  | "Asia/Tashkent"
  | "Asia/Tbilisi"
  | "Asia/Tehran"
  | "Asia/Thimphu"
  | "Asia/Tokyo"
  | "Asia/Tomsk"
  | "Asia/Ulaanbaatar"
  | "Asia/Urumqi"
  | "Asia/Ust-Nera"
  | "Asia/Vientiane"
  | "Asia/Vladivostok"
  | "Asia/Yakutsk"
  | "Asia/Yangon"
  | "Asia/Yekaterinburg"
  | "Asia/Yerevan"
  | "Atlantic/Azores"
  | "Atlantic/Bermuda"
  | "Atlantic/Canary"
  | "Atlantic/Cape_Verde"
  | "Atlantic/Faroe"
  | "Atlantic/Madeira"
  | "Atlantic/Reykjavik"
  | "Atlantic/South_Georgia"
  | "Atlantic/Stanley"
  | "Australia/Adelaide"
  | "Australia/Brisbane"
  | "Australia/Broken_Hill"
  | "Australia/Currie"
  | "Australia/Darwin"
  | "Australia/Eucla"
  | "Australia/Hobart"
  | "Australia/Lindeman"
  | "Australia/Lord_Howe"
  | "Australia/Melbourne"
  | "Australia/Perth"
  | "Australia/Sydney"
  | "Europe/Amsterdam"
  | "Europe/Andorra"
  | "Europe/Astrakhan"
  | "Europe/Athens"
  | "Europe/Belgrade"
  | "Europe/Berlin"
  | "Europe/Bratislava"
  | "Europe/Brussels"
  | "Europe/Bucharest"
  | "Europe/Budapest"
  | "Europe/Chisinau"
  | "Europe/Copenhagen"
  | "Europe/Dublin"
  | "Europe/Gibraltar"
  | "Europe/Guernsey"
  | "Europe/Helsinki"
  | "Europe/Isle_of_Man"
  | "Europe/Istanbul"
  | "Europe/Jersey"
  | "Europe/Kaliningrad"
  | "Europe/Kiev"
  | "Europe/Kirov"
  | "Europe/Lisbon"
  | "Europe/Ljubljana"
  | "Europe/London"
  | "Europe/Luxembourg"
  | "Europe/Madrid"
  | "Europe/Malta"
  | "Europe/Mariehamn"
  | "Europe/Minsk"
  | "Europe/Monaco"
  | "Europe/Moscow"
  | "Europe/Oslo"
  | "Europe/Paris"
  | "Europe/Podgorica"
  | "Europe/Prague"
  | "Europe/Riga"
  | "Europe/Rome"
  | "Europe/Samara"
  | "Europe/San_Marino"
  | "Europe/Sarajevo"
  | "Europe/Saratov"
  | "Europe/Simferopol"
  | "Europe/Skopje"
  | "Europe/Sofia"
  | "Europe/Stockholm"
  | "Europe/Tallinn"
  | "Europe/Tirane"
  | "Europe/Ulyanovsk"
  | "Europe/Uzhgorod"
  | "Europe/Vaduz"
  | "Europe/Vatican"
  | "Europe/Vienna"
  | "Europe/Vilnius"
  | "Europe/Volgograd"
  | "Europe/Warsaw"
  | "Europe/Zagreb"
  | "Europe/Zaporozhye"
  | "Europe/Zurich"
  | "Indian/Antananarivo"
  | "Indian/Chagos"
  | "Indian/Christmas"
  | "Indian/Cocos"
  | "Indian/Comoro"
  | "Indian/Kerguelen"
  | "Indian/Mahe"
  | "Indian/Maldives"
  | "Indian/Mauritius"
  | "Indian/Mayotte"
  | "Indian/Reunion"
  | "Pacific/Apia"
  | "Pacific/Auckland"
  | "Pacific/Bougainville"
  | "Pacific/Chatham"
  | "Pacific/Chuuk"
  | "Pacific/Easter"
  | "Pacific/Efate"
  | "Pacific/Enderbury"
  | "Pacific/Fakaofo"
  | "Pacific/Fiji"
  | "Pacific/Funafuti"
  | "Pacific/Galapagos"
  | "Pacific/Gambier"
  | "Pacific/Guadalcanal"
  | "Pacific/Guam"
  | "Pacific/Honolulu"
  | "Pacific/Kanton"
  | "Pacific/Kiritimati"
  | "Pacific/Kosrae"
  | "Pacific/Kwajalein"
  | "Pacific/Majuro"
  | "Pacific/Marquesas"
  | "Pacific/Midway"
  | "Pacific/Nauru"
  | "Pacific/Niue"
  | "Pacific/Norfolk"
  | "Pacific/Noumea"
  | "Pacific/Pago_Pago"
  | "Pacific/Palau"
  | "Pacific/Pitcairn"
  | "Pacific/Pohnpei"
  | "Pacific/Port_Moresby"
  | "Pacific/Rarotonga"
  | "Pacific/Saipan"
  | "Pacific/Tahiti"
  | "Pacific/Tarawa"
  | "Pacific/Tongatapu"
  | "Pacific/Wake"
  | "Pacific/Wallis"
  | "ACDT"
  | "ACST"
  | "ADT"
  | "AEDT"
  | "AEST"
  | "AKDT"
  | "AKST"
  | "AST"
  | "AWST"
  | "BST"
  | "CAT"
  | "CDT"
  | "CEST"
  | "CET"
  | "CST"
  | "EAT"
  | "EDT"
  | "EEST"
  | "EET"
  | "EST"
  | "GMT"
  | "HKT"
  | "HST"
  | "ICT"
  | "IST"
  | "JST"
  | "KST"
  | "MDT"
  | "MST"
  | "NZDT"
  | "NZST"
  | "PDT"
  | "PST"
  | "UTC"
  | "WAT"
  | "WET"
  | "WEST"
  | "UTC"
  | (string & Record<never, never>) // any other locale

// #endregion
